<template>
  <nuxt-link
    :to="localePath(props.to, locale)"
    @touchstart.stop
    @dblclick.stop
    :disabled="idle ? '' : null"
    :class="$style.root"
  >
    <slot />
  </nuxt-link>
</template>

<script setup lang="ts">
const nuxtApp = useNuxtApp()
const localePath = useLocalePath()
const idle = ref(false)

const props = defineProps({
  to: {
    type: [String, Object]
  },
  locale: {
    type: String,
    optional: true
  }

})

nuxtApp.hook('page:start', () => {
  idle.value = true
})

nuxtApp.hook('page:finish', () => {
  idle.value = false
})
</script>

<style module>
  .root[disabled] {
    pointer-events: none;
  }
</style>
