<template>
  <CoreButtonsBaseComponent>
    <SvgoCross :class="$style.svg" />
  </CoreButtonsBaseComponent>
</template>

<script lang="ts" setup>
</script>

<style module>
  .svg {
    overflow: visible;

    margin: 0;
    padding: 0;

    position: absolute;
    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);

    overflow: visible;
    width: 100%;
    height: 100%;
  }

  .svg * {
    fill: none;

    stroke: currentColor;
    stroke-width: var(--stroke--medium);
    stroke-linecap: round;
  }
</style>
