<template>
  <div
    v-if="image"
    :class="$style.root"
  >
    <BaseStrapiImageComponent
      :key="image?.id"
      :image="image"
      :class="$style.image"
    />

    <!-- <div
      v-if="image?.caption"
      :class="$style.caption"
    >
      {{ image?.caption }}
    </div> -->

    <i18nLink
      v-if="prevImageId"
      :class="$style.button"
      dir="prev"
      :to="setLightBoxImageIdQueryParams(prevImageId)"
    >
      <div :class="$style.buttonInner">
        <CoreButtonsArrowComponent />
      </div>
    </i18nLink>

    <i18nLink
      v-if="nextImageId"
      :class="$style.button"
      dir="next"
      :to="setLightBoxImageIdQueryParams(nextImageId)"
    >
      <div :class="$style.buttonInner">
        <CoreButtonsArrowComponent />
      </div>
    </i18nLink>
  </div>
  <div
    v-else
    :class="$style.error"
  >
    <div>
      {{ $t('lightbox.error') }}
      <slot name="buttonToClose" />
    </div>
  </div>
</template>

<script setup>
const route = useRoute()
const router = useRouter()

const id = computed(() => route?.query['lightbox-image-id'])

const { data, error } = await useAsyncData(() => {
  return $fetch('/api/strapi-rest', { query: { path: queryUploadById(id?.value) } }
  )
}, { watch: [id] })

const image = computed(() => data?.value?.length ? data?.value[0] : null)

const prevImageId = ref(null)
const nextImageId = ref(null)

const setLightBoxImageIdQueryParams = (id) => {
  const path = {
    path: route?.path,
    query: {
      ...route?.query,
      'lightbox-image-id': id
    }
  }
  return path
}

const setPrevNextImageId = () => {
  const images = document.documentElement.querySelectorAll('figure[data-image-zoom-allowed]')
  const imageIdIndex = Array.from(images)?.findIndex(image => image?.dataset?.uploadId === id?.value)

  if (imageIdIndex > 0) {
    prevImageId.value = images[imageIdIndex - 1]?.dataset?.uploadId
  } else {
    prevImageId.value = null
  }

  if (imageIdIndex < (images?.length - 1)) {
    nextImageId.value = images[imageIdIndex + 1]?.dataset?.uploadId
  } else {
    nextImageId.value = null
  }
}

onMounted(() => {
  setPrevNextImageId()
})

// NOTE should happen on change of dom
watch(image, () => {
  setPrevNextImageId()
})

</script>

<style module lang="scss">
.root {
  height: 100%;

  display: flex;
  flex-direction: column;

  position: relative;
}

.image {
  flex: 1;
  overflow: hidden;
  --base--image--height: calc(100% - (var(--unit--default) * 2));
  user-select: none;
}

/* extract */
.button {
  composes: reset-button from global;

  position: absolute;
  top: 50%;

  transform: translateY(-50%);

  margin-left: 0;
  margin-right: 0;
}

.button[dir='prev'] {
  left: 0;
}

.button[dir='next'] {
  right: 0;
}

.buttonInner {
  transform: translateY(-50%);

  width: var(--size--button);
  height: var(--size--button);
}

.button[dir='next'] .buttonInner {
  transform: rotate(180deg);
}

.caption {
  composes: font-size-default from global;
  color: var(--color--primary);
  padding: calc(var(--unit--default)  / 2);
  text-align: center;
}

.error {
  composes: font-size-large from global;

  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  height: 100%;
  color: var(--color--primary);
}
</style>
