<script setup lang="ts">
const nuxtApp = useNuxtApp()
const { $i18n } = nuxtApp

const routeName = computed(() => nuxtApp.$getRouteBaseName())
useHead({
  titleTemplate: (titleChunk) => {
    return titleChunk ? `${titleChunk} - ${$i18n.t('siteTitle')}` : `${$i18n.t('siteTitle')}`
  },
  script: [
    {
      src: 'https://unpkg.com/large-small-dynamic-viewport-units-polyfill@0.0.4/dist/large-small-dynamic-viewport-units-polyfill.min.js'
    }
  ],
  htmlAttrs: {
    'data-route-name': routeName
  }
})
</script>

<template>
  <div id="app" :class="$style.root" :data-route="routeName">
    <NuxtLayout :class="$style.layout">
      <NuxtLoadingIndicator color="var(--color--magenta)" />
      <NuxtPage :class="$style.page" />
      <CoreLightBoxComponent />
    </NuxtLayout>
  </div>
</template>

<style module>
.root {
  position: relative;

  display: flex;
  flex-direction: column;

  min-height: var(--100vh);
}

.page {
  flex: 1;
}
</style>
