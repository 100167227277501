<template>
  <transition name="fade">
    <template v-if="hasQueryParams">
      <div
        ref="element"
        :class="$style.root"
        tabindex="0"
        @keyup.esc="closeLightBox()"
      >
        <div :class="$style.content">
          <button
            :class="$style.closeButton"
            @click="closeLightBox"
          >
            <CoreButtonsCloseComponent />
          </button>

          <CoreLightBoxImageComponent>
            <template #buttonToClose>
              <button
                :class="$style.closeButtonInline"
                @click="closeLightBox"
              >
                {{ $t('shared.close') }}
              </button>
            </template>
          </CoreLightBoxImageComponent>
        </div>
      </div>
    </template>
  </transition>
</template>

<script setup>
import { omit } from 'lodash-es'

const route = useRoute()
const hasQueryParams = computed(() => {
  return route?.query['lightbox-image-id']
})

const router = useRouter()
const closeLightBox = () => {
  const queryParams = omit(route?.query || {}, ['lightbox-image-id'])
  router.push({ query: queryParams })
}

// FIXME should lock on connect as well
const element = ref(null)
watch(hasQueryParams, (value) => {
  if (value) {
    document.documentElement.setAttribute('data-overflow-hidden', '')
    element?.value?.focus()
  } else {
    document.documentElement.removeAttribute('data-overflow-hidden')
  }
})

</script>

<style module>
.root {
  position: fixed;
  z-index: var(--z-index--light-box);

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background-color: var(--lightbox--color--background, var(--color--background));

  display: flex;
}

.root:focus {
  outline: none;
}

.content {
  width: 100vw;
  height: var(--100vh);

  padding: var(--unit--default);

  position: relative;
}

.closeButton {
  composes: reset-button from global;

  position: absolute;
  z-index: 1;
  top: var(--unit--default);
  right: var(--unit--default);
}

.closeButton:hover svg * {
  stroke: var(--color--magenta);
}

.closeButtonInline {
  composes: reset-button from global;
  text-decoration-line: underline;
}
</style>
