import { default as _91slug_93S0js3RtOm6Meta } from "/src/pages/[slug].vue?macro=true";
import { default as _91slug_93kRWKSjjDGiMeta } from "/src/pages/bulletin-board/[slug].vue?macro=true";
import { default as indexbBQ0Ia6bgLMeta } from "/src/pages/bulletin-board/index.vue?macro=true";
import { default as _91slug_937x5OJ9KD1ZMeta } from "/src/pages/calendar/[slug].vue?macro=true";
import { default as indexoomUQvNvBAMeta } from "/src/pages/calendar/index.vue?macro=true";
import { default as _91slug_93VQpRrPulDjMeta } from "/src/pages/community/[slug].vue?macro=true";
import { default as indexZmZTnlZs88Meta } from "/src/pages/community/index.vue?macro=true";
import { default as indexvsa0KwJWqiMeta } from "/src/pages/index.vue?macro=true";
import { default as newsletterl9CBif8S4RMeta } from "/src/pages/newsletter.vue?macro=true";
import { default as _91slug_93faQg4PFwGqMeta } from "/src/pages/organisation/[slug].vue?macro=true";
import { default as organisationKTdqDKpceoMeta } from "/src/pages/organisation.vue?macro=true";
import { default as page_45previewqdX5u0GUuHMeta } from "/src/node_modules/@brambergvd/nuxt-base-layer/pages/page-preview.vue?macro=true";
import { default as _91slug_93fANIPeAHJ0Meta } from "/src/pages/projects/[slug].vue?macro=true";
import { default as indexJ39cZJ81HOMeta } from "/src/pages/projects/index.vue?macro=true";
export default [
  {
    name: _91slug_93S0js3RtOm6Meta?.name ?? "slug___nl",
    path: _91slug_93S0js3RtOm6Meta?.path ?? "/:slug()",
    meta: _91slug_93S0js3RtOm6Meta || {},
    alias: _91slug_93S0js3RtOm6Meta?.alias || [],
    redirect: _91slug_93S0js3RtOm6Meta?.redirect || undefined,
    component: () => import("/src/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93S0js3RtOm6Meta?.name ?? "slug___en",
    path: _91slug_93S0js3RtOm6Meta?.path ?? "/en/:slug()",
    meta: _91slug_93S0js3RtOm6Meta || {},
    alias: _91slug_93S0js3RtOm6Meta?.alias || [],
    redirect: _91slug_93S0js3RtOm6Meta?.redirect || undefined,
    component: () => import("/src/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kRWKSjjDGiMeta?.name ?? "bulletin-board-slug___nl",
    path: _91slug_93kRWKSjjDGiMeta?.path ?? "/prikbord/:slug",
    meta: _91slug_93kRWKSjjDGiMeta || {},
    alias: _91slug_93kRWKSjjDGiMeta?.alias || [],
    redirect: _91slug_93kRWKSjjDGiMeta?.redirect || undefined,
    component: () => import("/src/pages/bulletin-board/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kRWKSjjDGiMeta?.name ?? "bulletin-board-slug___en",
    path: _91slug_93kRWKSjjDGiMeta?.path ?? "/en/bulletin-board/:slug",
    meta: _91slug_93kRWKSjjDGiMeta || {},
    alias: _91slug_93kRWKSjjDGiMeta?.alias || [],
    redirect: _91slug_93kRWKSjjDGiMeta?.redirect || undefined,
    component: () => import("/src/pages/bulletin-board/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexbBQ0Ia6bgLMeta?.name ?? "bulletin-board___nl",
    path: indexbBQ0Ia6bgLMeta?.path ?? "/prikbord",
    meta: indexbBQ0Ia6bgLMeta || {},
    alias: indexbBQ0Ia6bgLMeta?.alias || [],
    redirect: indexbBQ0Ia6bgLMeta?.redirect || undefined,
    component: () => import("/src/pages/bulletin-board/index.vue").then(m => m.default || m)
  },
  {
    name: indexbBQ0Ia6bgLMeta?.name ?? "bulletin-board___en",
    path: indexbBQ0Ia6bgLMeta?.path ?? "/en/bulletin-board",
    meta: indexbBQ0Ia6bgLMeta || {},
    alias: indexbBQ0Ia6bgLMeta?.alias || [],
    redirect: indexbBQ0Ia6bgLMeta?.redirect || undefined,
    component: () => import("/src/pages/bulletin-board/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_937x5OJ9KD1ZMeta?.name ?? "calendar-slug___nl",
    path: _91slug_937x5OJ9KD1ZMeta?.path ?? "/activiteiten/:slug",
    meta: _91slug_937x5OJ9KD1ZMeta || {},
    alias: _91slug_937x5OJ9KD1ZMeta?.alias || [],
    redirect: _91slug_937x5OJ9KD1ZMeta?.redirect || undefined,
    component: () => import("/src/pages/calendar/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_937x5OJ9KD1ZMeta?.name ?? "calendar-slug___en",
    path: _91slug_937x5OJ9KD1ZMeta?.path ?? "/en/activities/:slug",
    meta: _91slug_937x5OJ9KD1ZMeta || {},
    alias: _91slug_937x5OJ9KD1ZMeta?.alias || [],
    redirect: _91slug_937x5OJ9KD1ZMeta?.redirect || undefined,
    component: () => import("/src/pages/calendar/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexoomUQvNvBAMeta?.name ?? "calendar___nl",
    path: indexoomUQvNvBAMeta?.path ?? "/activiteiten",
    meta: indexoomUQvNvBAMeta || {},
    alias: indexoomUQvNvBAMeta?.alias || [],
    redirect: indexoomUQvNvBAMeta?.redirect || undefined,
    component: () => import("/src/pages/calendar/index.vue").then(m => m.default || m)
  },
  {
    name: indexoomUQvNvBAMeta?.name ?? "calendar___en",
    path: indexoomUQvNvBAMeta?.path ?? "/en/activities",
    meta: indexoomUQvNvBAMeta || {},
    alias: indexoomUQvNvBAMeta?.alias || [],
    redirect: indexoomUQvNvBAMeta?.redirect || undefined,
    component: () => import("/src/pages/calendar/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93VQpRrPulDjMeta?.name ?? "community-slug___nl",
    path: _91slug_93VQpRrPulDjMeta?.path ?? "/gemeenschap/:slug",
    meta: _91slug_93VQpRrPulDjMeta || {},
    alias: _91slug_93VQpRrPulDjMeta?.alias || [],
    redirect: _91slug_93VQpRrPulDjMeta?.redirect || undefined,
    component: () => import("/src/pages/community/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93VQpRrPulDjMeta?.name ?? "community-slug___en",
    path: _91slug_93VQpRrPulDjMeta?.path ?? "/en/community/:slug",
    meta: _91slug_93VQpRrPulDjMeta || {},
    alias: _91slug_93VQpRrPulDjMeta?.alias || [],
    redirect: _91slug_93VQpRrPulDjMeta?.redirect || undefined,
    component: () => import("/src/pages/community/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexZmZTnlZs88Meta?.name ?? "community___nl",
    path: indexZmZTnlZs88Meta?.path ?? "/gemeenschap",
    meta: indexZmZTnlZs88Meta || {},
    alias: indexZmZTnlZs88Meta?.alias || [],
    redirect: indexZmZTnlZs88Meta?.redirect || undefined,
    component: () => import("/src/pages/community/index.vue").then(m => m.default || m)
  },
  {
    name: indexZmZTnlZs88Meta?.name ?? "community___en",
    path: indexZmZTnlZs88Meta?.path ?? "/en/community",
    meta: indexZmZTnlZs88Meta || {},
    alias: indexZmZTnlZs88Meta?.alias || [],
    redirect: indexZmZTnlZs88Meta?.redirect || undefined,
    component: () => import("/src/pages/community/index.vue").then(m => m.default || m)
  },
  {
    name: indexvsa0KwJWqiMeta?.name ?? "index___nl",
    path: indexvsa0KwJWqiMeta?.path ?? "/",
    meta: indexvsa0KwJWqiMeta || {},
    alias: indexvsa0KwJWqiMeta?.alias || [],
    redirect: indexvsa0KwJWqiMeta?.redirect || undefined,
    component: () => import("/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexvsa0KwJWqiMeta?.name ?? "index___en",
    path: indexvsa0KwJWqiMeta?.path ?? "/en",
    meta: indexvsa0KwJWqiMeta || {},
    alias: indexvsa0KwJWqiMeta?.alias || [],
    redirect: indexvsa0KwJWqiMeta?.redirect || undefined,
    component: () => import("/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: newsletterl9CBif8S4RMeta?.name ?? "newsletter___nl",
    path: newsletterl9CBif8S4RMeta?.path ?? "/nieuwsbrief",
    meta: newsletterl9CBif8S4RMeta || {},
    alias: newsletterl9CBif8S4RMeta?.alias || [],
    redirect: newsletterl9CBif8S4RMeta?.redirect || undefined,
    component: () => import("/src/pages/newsletter.vue").then(m => m.default || m)
  },
  {
    name: newsletterl9CBif8S4RMeta?.name ?? "newsletter___en",
    path: newsletterl9CBif8S4RMeta?.path ?? "/en/newsletter",
    meta: newsletterl9CBif8S4RMeta || {},
    alias: newsletterl9CBif8S4RMeta?.alias || [],
    redirect: newsletterl9CBif8S4RMeta?.redirect || undefined,
    component: () => import("/src/pages/newsletter.vue").then(m => m.default || m)
  },
  {
    name: organisationKTdqDKpceoMeta?.name ?? "organisation___nl",
    path: organisationKTdqDKpceoMeta?.path ?? "/organisatie",
    children: [
  {
    name: _91slug_93faQg4PFwGqMeta?.name ?? "organisation-slug___nl",
    path: _91slug_93faQg4PFwGqMeta?.path ?? "/organisatie/:slug",
    meta: _91slug_93faQg4PFwGqMeta || {},
    alias: _91slug_93faQg4PFwGqMeta?.alias || [],
    redirect: _91slug_93faQg4PFwGqMeta?.redirect || undefined,
    component: () => import("/src/pages/organisation/[slug].vue").then(m => m.default || m)
  }
],
    meta: organisationKTdqDKpceoMeta || {},
    alias: organisationKTdqDKpceoMeta?.alias || [],
    redirect: organisationKTdqDKpceoMeta?.redirect || undefined,
    component: () => import("/src/pages/organisation.vue").then(m => m.default || m)
  },
  {
    name: organisationKTdqDKpceoMeta?.name ?? "organisation___en",
    path: organisationKTdqDKpceoMeta?.path ?? "/en/organisation",
    children: [
  {
    name: _91slug_93faQg4PFwGqMeta?.name ?? "organisation-slug___en",
    path: _91slug_93faQg4PFwGqMeta?.path ?? "/en/organisation/:slug",
    meta: _91slug_93faQg4PFwGqMeta || {},
    alias: _91slug_93faQg4PFwGqMeta?.alias || [],
    redirect: _91slug_93faQg4PFwGqMeta?.redirect || undefined,
    component: () => import("/src/pages/organisation/[slug].vue").then(m => m.default || m)
  }
],
    meta: organisationKTdqDKpceoMeta || {},
    alias: organisationKTdqDKpceoMeta?.alias || [],
    redirect: organisationKTdqDKpceoMeta?.redirect || undefined,
    component: () => import("/src/pages/organisation.vue").then(m => m.default || m)
  },
  {
    name: page_45previewqdX5u0GUuHMeta?.name ?? "page-preview",
    path: page_45previewqdX5u0GUuHMeta?.path ?? "/page-preview",
    meta: page_45previewqdX5u0GUuHMeta || {},
    alias: page_45previewqdX5u0GUuHMeta?.alias || [],
    redirect: page_45previewqdX5u0GUuHMeta?.redirect || undefined,
    component: () => import("/src/node_modules/@brambergvd/nuxt-base-layer/pages/page-preview.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93fANIPeAHJ0Meta?.name ?? "projects-slug___nl",
    path: _91slug_93fANIPeAHJ0Meta?.path ?? "/projecten/:slug",
    meta: _91slug_93fANIPeAHJ0Meta || {},
    alias: _91slug_93fANIPeAHJ0Meta?.alias || [],
    redirect: _91slug_93fANIPeAHJ0Meta?.redirect || undefined,
    component: () => import("/src/pages/projects/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93fANIPeAHJ0Meta?.name ?? "projects-slug___en",
    path: _91slug_93fANIPeAHJ0Meta?.path ?? "/en/projects/:slug",
    meta: _91slug_93fANIPeAHJ0Meta || {},
    alias: _91slug_93fANIPeAHJ0Meta?.alias || [],
    redirect: _91slug_93fANIPeAHJ0Meta?.redirect || undefined,
    component: () => import("/src/pages/projects/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexJ39cZJ81HOMeta?.name ?? "projects___nl",
    path: indexJ39cZJ81HOMeta?.path ?? "/projecten",
    meta: indexJ39cZJ81HOMeta || {},
    alias: indexJ39cZJ81HOMeta?.alias || [],
    redirect: indexJ39cZJ81HOMeta?.redirect || undefined,
    component: () => import("/src/pages/projects/index.vue").then(m => m.default || m)
  },
  {
    name: indexJ39cZJ81HOMeta?.name ?? "projects___en",
    path: indexJ39cZJ81HOMeta?.path ?? "/en/projects",
    meta: indexJ39cZJ81HOMeta || {},
    alias: indexJ39cZJ81HOMeta?.alias || [],
    redirect: indexJ39cZJ81HOMeta?.redirect || undefined,
    component: () => import("/src/pages/projects/index.vue").then(m => m.default || m)
  }
]