import { defineNuxtPlugin } from '#app/nuxt'
import { LazySvgoArrow, LazySvgoCross, LazySvgoGgBg1, LazySvgoGgBg10, LazySvgoGgBg11, LazySvgoGgBg12, LazySvgoGgBg13, LazySvgoGgBg14, LazySvgoGgBg15, LazySvgoGgBg16, LazySvgoGgBg17, LazySvgoGgBg2, LazySvgoGgBg3, LazySvgoGgBg4, LazySvgoGgBg5, LazySvgoGgBg6, LazySvgoGgBg7, LazySvgoGgBg8, LazySvgoGgBg9, LazySvgoLine, LazySvgoLogo, LazySvgoMap, LazySvgoMinus, LazySvgoPlus } from '#components'
const lazyGlobalComponents = [
  ["SvgoArrow", LazySvgoArrow],
["SvgoCross", LazySvgoCross],
["SvgoGgBg1", LazySvgoGgBg1],
["SvgoGgBg10", LazySvgoGgBg10],
["SvgoGgBg11", LazySvgoGgBg11],
["SvgoGgBg12", LazySvgoGgBg12],
["SvgoGgBg13", LazySvgoGgBg13],
["SvgoGgBg14", LazySvgoGgBg14],
["SvgoGgBg15", LazySvgoGgBg15],
["SvgoGgBg16", LazySvgoGgBg16],
["SvgoGgBg17", LazySvgoGgBg17],
["SvgoGgBg2", LazySvgoGgBg2],
["SvgoGgBg3", LazySvgoGgBg3],
["SvgoGgBg4", LazySvgoGgBg4],
["SvgoGgBg5", LazySvgoGgBg5],
["SvgoGgBg6", LazySvgoGgBg6],
["SvgoGgBg7", LazySvgoGgBg7],
["SvgoGgBg8", LazySvgoGgBg8],
["SvgoGgBg9", LazySvgoGgBg9],
["SvgoLine", LazySvgoLine],
["SvgoLogo", LazySvgoLogo],
["SvgoMap", LazySvgoMap],
["SvgoMinus", LazySvgoMinus],
["SvgoPlus", LazySvgoPlus],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
