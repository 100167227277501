<template>
  <button :class="$style.root">
    <slot />
  </button>
</template>

<script lang="ts" setup>
</script>

<style module>
.root {
  composes: reset-button from global;

  width: var(--size--button);
  height: var(--size--button);

  position: relative;
}

.root[dir='next'] {
  transform: rotateZ(180deg);
}

.root[disabled] {
  pointer-events: none;
}

.root[disabled] * {
  stroke: var(--color--gray);
}
</style>
