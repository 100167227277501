<template>
  <div :class="$style.root">
    <button :class="$style.button" @click="goBack">Back to homepage</button>
  </div>
</template>

<script>
export default {
  methods: {
    goBack() {
      this.$router.push('/')
    }
  }
}
</script>

<style module>
.root {
  composes: font-default font-size-large from global;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--lh);

  height: var(--100vh);

  background-color: var(--color--brown);
  color: var(--color--white);
}

.button {
  composes: reset-button link from global;
}
</style>
